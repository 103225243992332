.questionText {
    text-align: center;
    margin-bottom: 30px;
}

.questionContent {
    display: grid;
    grid-template-areas:
        "a b"
        "c d";
    grid-row-gap: 20px;
    width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    align-items: center;
    justify-items: center;
}

.progressBar_tui {
    width: 600px;
}

.questionActions {
    margin: auto;
    margin-top: 20px;
    width: 350px;
    text-align: center;
}

.templateUIOkButton,
.templateUIBackButton {
    width: 120px;
    height: 80px;
    border: none;
    color: #fff;
}

.templateUIOkButton {
    background-color: forestgreen;
    float: right;
}

.templateUIBackButton {
    background-color: #444;
    float: left;
}
