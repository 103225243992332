.thankyouContainer {
    display: flex;
    align-items: center;
}
.thankyouContainer > div {
    text-align: center;
    width: 100%;
}
.thankyouContainer .header {
    opacity: 0;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: normal;
    min-height: unset;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.25s forwards
        init-thankyou-header;
}
@keyframes init-thankyou-header {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.thankyou-icon {
    opacity: 0;
    display: block;
    width: 4rem;
    height: 4rem;
    margin: 0 auto 1rem;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards init-thankyou-icon;
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='74' height='74' viewBox='0 0 74 74'%3E%3Cdefs%3E%3Cfilter id='a' x='0' y='0' width='74' height='74' filterUnits='userSpaceOnUse'%3E%3CfeOffset dy='3' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='3' result='b'/%3E%3CfeFlood flood-color='%2310294b' flood-opacity='0.239'/%3E%3CfeComposite operator='in' in2='b'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='translate(-170 -270)'%3E%3Cg transform='matrix(1, 0, 0, 1, 170, 270)' filter='url(%23a)'%3E%3Crect width='56' height='56' rx='28' transform='translate(9 6)' fill='%2310294b'/%3E%3C/g%3E%3Cpath d='M12.284-17.114l-2.523-2.5L-3.489-6.727-9.8-12.807-12.284-10.3l8.8,8.58Z' transform='translate(207 315)' fill='%23f77197'/%3E%3C/g%3E%3C/svg%3E");
}
@keyframes init-thankyou-icon {
    0% {
        opacity: 0;
        transform: scale(0) translateY(-5rem);
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

.continue-button {
    margin-top: 10%;
    background-color: #10294b; /* Green */
    border: 2px solid #343a40;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}
.continue-button:hover {
    border: 2px solid #f77196; /* Green */
    color: white;
}
