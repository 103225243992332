.container-ynm .progressBar_ynm {
    margin-bottom: 1em;
}

.progressBar_ynm .progress-bar {
    background-color: #10294b;
    position: relative;
}

.progressBar_ynm .progress {
    overflow: unset;
    height: 0.375rem;
    background-color: #c2c8d1;
    box-shadow: 0 0.5em 1em #c2c8d1;
    border-radius: 0;
}
.progressBar_ynm .progress_bar_label {
    position: absolute;
    right: 0;
    top: 0.25rem;
    color: #757575;
    text-align: right;
    font-size: 0.625rem;
}
