.container_ks > * {
    width: 100%;
    margin: 0;
}

.image_container_ks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 65vh;
    max-height: 65vh;
}

.image_container_ks img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: inherit;
    max-height: 65vh;
}
@media all and (min-height: 667px) {
    .image_container_ks,
    .image_container_ks img {
        max-height: 70vh;
    }
}
@media all and (min-height: 824px) {
    .image_container_ks,
    .image_container_ks img {
        max-height: unset;
    }
    .image_container_ks {
        min-width: unset;
    }
}

.title_ks {
    opacity: 0;
    animation: 0.75s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards init-title_ks;
}
@keyframes init-title_ks {
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.title_ks h1 {
    font-size: 22pt;
}

.progress_container_ks {
    margin-bottom: 15%;
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s forwards
        init-actions_container_ks;
}

@keyframes init-progress_container_ks {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.actions_container_ks {
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s forwards
        init-actions_container_ks;
}

@keyframes init-actions_container_ks {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
