@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/font/inter/Inter-Regular.woff2?v=3.12") format("woff2"),
        url("/font/inter/Inter-Regular.woff?v=3.12") format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("/font/inter/Inter-Italic.woff2?v=3.12") format("woff2"),
        url("/font/inter/Inter-Italic.woff?v=3.12") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/font/inter/Inter-Medium.woff2?v=3.12") format("woff2"),
        url("/font/inter/Inter-Medium.woff?v=3.12") format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("/font/inter/Inter-MediumItalic.woff2?v=3.12") format("woff2"),
        url("/font/inter/Inter-MediumItalic.woff?v=3.12") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/font/inter/Inter-Bold.woff2?v=3.12") format("woff2"),
        url("/font/inter/Inter-Bold.woff?v=3.12") format("woff");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("/font/inter/Inter-BoldItalic.woff2?v=3.12") format("woff2"),
        url("/font/inter/Inter-BoldItalic.woff?v=3.12") format("woff");
}
