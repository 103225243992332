.testSquare {
    display: flex;
    justify-content: center;

    width: 250px;
    height: 200px;

    background-color: #ff2f2f;
    color: #fff;
}

.testSquare.blue {
    background-color: #0074d3;
}

.testSquare.selected {
    border: 10px solid deepskyblue;
}

.testSquare:hover {
    box-shadow: 2px 2px 2px 3px black;
}

.testSquare .testSquareText {
    font-size: 24pt;
    margin: auto;
}
