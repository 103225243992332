.previewInfoContainer {
    position: fixed;
    top: 0;
    height: fit-content;
    min-height: 100px;
    width: 100%;
    z-index: 999;
    background-color: red;
    vertical-align: middle;
    display: flex;
    line-height: normal;
    opacity: 0.4;
}

.previewInfoContainer .previewInfoText {
    margin: auto;
    color: white;
    font-size: 2rem;
    text-align: center;
}