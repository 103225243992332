.container_zks > * {
    width: 100%;
    margin: 0;
}

.image_container_zks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 65vh;
    max-height: 65vh;
    overflow: hidden;
    background-color: black;
}

.image_container_zks img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: inherit;
    max-height: 65vh;
}

.img-overlay-wrap {
    transition: transform 150ms ease-in-out;
    object-fit: none;
}

.img-overlay-wrap svg {
    position: absolute;
    top: 0;
    left: 0;
}

@media all and (min-height: 667px) {
    .image_container_zks,
    .image_container_zks img {
        max-height: 70vh;
    }
}
@media all and (min-height: 824px) {
    .image_container_zks,
    .image_container_zks img {
        max-height: unset;
    }
    .image_container_zks {
        min-width: unset;
    }
}

.title_zks {
    opacity: 0;
    animation: 0.75s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards init-title_zks;
}
@keyframes init-title_zks {
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.title_zks h1 {
    font-size: 22pt;
}

.progress_container_zks {
    margin-bottom: 15%;
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s forwards
        init-actions_container_zks;
}

@keyframes init-progress_container_zks {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.actions_container_zks {
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s forwards
        init-actions_container_zks;
}

@keyframes init-actions_container_zks {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
