.audioplayer_view_question {
    text-align: center;
    margin-bottom: 30px;
}

.audioplayer_view_question_emotional_label {
    color: black;
    text-decoration: underline;
}

.audioplayer_view_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.audioplayer_view_content_container {
    width: 600px;
    margin: auto;
}

.audioplayer_view_progressbar {
    width: 100%;
    margin: auto;
    margin-top: 30px;
}

.audioplayer_view_actions {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    width: 100%;
    text-align: center;
}

.audioplayer_view_actions button {
    width: 120px;
    margin: auto;
    height: 80px;
    border: none;
    color: #fff;
    font-size: 22pt;
    cursor: pointer;
}

.audioplayer_view_actions button:disabled {
    opacity: 0.4;
}

.audioplayer_view_back_button {
    background-color: #444;
}

.audioplayer_view_no_button {
    background-color: #ff0000;
}
.audioplayer_view_no_button:hover {
    background-color: #bb0000;
}

.audioplayer_view_yes_button {
    background-color: #009900;
}
.audioplayer_view_yes_button:hover {
    background-color: #006600;
}

.audioplayer_view_unsure_button {
    background-color: #0000ff;
}
.audioplayer_view_unsure_button:hover {
    background-color: #0000aa;
}
