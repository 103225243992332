.instructionsCarouselContainer {
    text-align: center;
}

.carousel.slide {
    min-height: 300px;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.carousel.slide,
.carousel-inner,
.carousel-item,
.instructionsCarouselSlide {
    text-align: center;
}

.instructionsCarouselSlide img {
    box-shadow: 0 0.5em 1em rgba(16, 41, 75, 0.1);
}
.instructionsCarouselSlide p {
    max-width: 334px;
    margin: 2.5rem auto 1.5rem;
    min-height: 4rem;
}

ol.carousel-indicators {
    bottom: 5.25rem;
}
ol.carousel-indicators li {
    background-color: gray;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
}
ol.carousel-indicators li.active {
    background-color: #10294b;
}

.carousel-control-prev,
.carousel-control-next {
    background-color: #10294b;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    top: unset;
    bottom: 6.75rem;
    box-shadow: 0 0.5em 1em rgba(16, 41, 75, 0.24);
    opacity: 1;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.carousel-control-prev:focus,
.carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
    opacity: 1;
    background-color: #0a1a30;
}
.carousel-control-prev {
    transform: translateX(-0.5rem);
}
@media all and (min-width: 480px) {
    .carousel-control-prev {
        transform: translateX(-1.25rem);
    }
}
.carousel-control-prev-icon {
    background-size: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Crect width='16' height='16' fill='%23f77197' opacity='0'/%3E%3Cpath d='M1.079,7.47,7.152,1.4a.75.75,0,0,1,1.061,0l.708.708a.75.75,0,0,1,0,1.059L4.109,8l4.813,4.836a.75.75,0,0,1,0,1.059l-.708.708a.75.75,0,0,1-1.061,0L1.079,8.53A.75.75,0,0,1,1.079,7.47Z' transform='translate(2.141 -0.177)' fill='%23f77197'/%3E%3C/svg%3E");
}

.carousel-control-next {
    transform: translateX(0.5rem);
}
@media all and (min-width: 480px) {
    .carousel-control-next {
        transform: translateX(1.25rem);
    }
}
.carousel-control-next-icon {
    background-size: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Crect width='16' height='16' fill='%23f77197' opacity='0'/%3E%3Cpath d='M8.921,8.53,2.848,14.6a.75.75,0,0,1-1.061,0L1.079,13.9a.75.75,0,0,1,0-1.059L5.891,8,1.078,3.164a.75.75,0,0,1,0-1.059L1.787,1.4a.75.75,0,0,1,1.061,0L8.921,7.47A.75.75,0,0,1,8.921,8.53Z' transform='translate(4.141 -0.177)' fill='%23f77197'/%3E%3C/svg%3E");
}

.instructionsCarouselContainer .closeInstructionsButton {
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards
        init-instruction-close-button;
}
@keyframes init-instruction-close-button {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
