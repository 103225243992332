.container-qm.container_pc {
    max-width: 1400px;
}

.container_pc > * {
    width: 100%;
    margin: 0;
}

.image_container_pc {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
    overflow: hidden;
    min-height: 40vh;
    max-height: 60vh;
}

.growable {
    flex: 1;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.img-overlay-wrap {
    height: 100%;
    width: fit-content;
}

.img-overlay-wrap canvas {
    transition: transform 150ms ease-in-out;
    height: 100%;
    max-height: 45vh;
    object-fit: contain;
}

.img-overlay-wrap .canvas_on_canvas {
    position: absolute;
    left: 0px;
    top: 0px;
}

.img-overlay-wrap svg {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    max-height: 45vh;
}

.icon_container {
    margin-right: 2%;
}

.slider_container {
    margin-right: 5%;
    margin-left: 5%;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards
        init-fade_in_from_bottom;
}

.title_pc {
    text-align: center;
    opacity: 0;
    animation: 0.75s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards
        init-fade_in_from_top;
}

.title_pc h1 {
    margin-top: 2%;
    font-size: 22pt;
}

.progress_container_pc {
    margin-right: 2.5%;
    margin-left: 2.5%;
    width: 95%;
    margin-top: 5px;
    margin-bottom: 5px;
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s forwards
        init-fade_in_from_top;
}

.submit_actions_container {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 1%;
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s forwards
        init-fade_in_from_bottom;
}

.actions_container_pc {
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 1%;
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s forwards
        init-fade_in_from_bottom;
}

@keyframes init-fade_in_from_top {
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes init-fade_in_from_bottom {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.debug_container_pc {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 5px;
}

.debug_container_pc button {
    padding: 8px;
}
