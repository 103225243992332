.submitContainer {
    display: flex;
    align-items: center;
}
.submitContainer > div {
    text-align: center;
    width: 100%;
}

.submitContainer .header {
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards init-submit-header;
}
@keyframes init-submit-header {
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.submitContainer .actions {
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.25s forwards
        init-submit-actions;
}
@keyframes init-submit-actions {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
