html,
body,
#root {
    height: 100%;
    color: #10294b;
    line-height: 1.25rem;
    margin: 0;
    display: block;
}

body {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #c2c8d1;
    background-position: 50% 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='300' height='300' viewBox='0 0 300 300'%3E%3Cg transform='translate(-20.5 4.5)' opacity='0.25'%3E%3Cline y2='100' transform='translate(170.5 95.5)' fill='none' stroke='%2310294b' stroke-width='1'/%3E%3Cline y2='100' transform='translate(220.5 145.5) rotate(90)' fill='none' stroke='%2310294b' stroke-width='1'/%3E%3Crect width='300' height='300' transform='translate(20.5 -4.5)' fill='none'/%3E%3C/g%3E%3C/svg%3E");
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

p {
    padding-left: 1rem;
    padding-right: 1rem;
}

svg {
    pointer-events: none; /* pass events to object behind svg */
}

/* CONTAINER */
.container-qm {
    max-width: 414px;
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    overflow: hidden;
    padding: 0;
    border-radius: 0;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    align-items: center;
}
@media all and (min-width: 480px) {
    .container-qm {
        overflow: unset;
        border-radius: 32px;
    }
}
@media all and (min-height: 850px) {
    .container-qm {
        min-height: 568px;
        height: auto;
    }
}

@media all and (min-width: 480px) {
    .container-ynm .image_container,
    .carousel-inner {
        border-top-left-radius: 32px;
        border-top-right-radius: 32px;
    }
    .container-ynm .image_container {
        overflow: hidden;
    }
}

/* HEADER */
.header {
    text-align: center;
    font-weight: bold;
    min-height: 3.75rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    max-width: 334px;
}
.header p {
    width: 100%;
}

/* IMAGES */
.image {
    display: block;
}

.image img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 65vh;
}
@media all and (min-height: 667px) {
    .image img {
        max-height: 70vh;
    }
}
@media all and (min-height: 824px) {
    .image img {
        max-height: unset;
    }
}

/* DEFAULT BUTTONS */
.button {
    background-color: #fff;
    color: #10294b;
    box-shadow: 0 0.5rem 1rem rgba(16, 41, 75, 0.08);
    font-weight: normal;
    border: 0;
    border-radius: 0.5rem;
    margin: 0 0.25rem 1rem;
    padding: 0.875rem 1.5rem 0.938rem;
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}

.button:focus,
.button:hover {
    background-color: #f5f5f5;
    color: #10294b;
    transform: translateY(-0.15rem);
}

.button:active {
    transform: translateY(0);
}

.button:disabled {
    opacity: 0.4;
}

.button.is-sm {
    padding: 0.375rem 1rem 0.438rem;
}

/* ACTIONS */
.actions {
    margin-bottom: 1em;
}
.actionButtonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.actions .button {
    margin-bottom: 0.5em;
}

.meta-actions > div {
    display: flex;
    justify-content: center;
}

/* BUTTON COLORS */
.is-primary {
    background-color: #f77197;
    color: #10294b;
    box-shadow: 0 0.5rem 1rem rgba(247, 113, 151, 0.32);
    font-weight: 500;
}
.button.is-primary:focus,
.button.is-primary:hover {
    background-color: #de6588;
    color: #10294b;
}

.is-red {
    background-color: #a30000;
    color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(163, 0, 0, 0.24);
    font-weight: 500;
}
.button.is-red:focus,
.button.is-red:hover {
    background-color: #8a0000;
    color: #fff;
}

.is-green {
    background-color: #2e7d32;
    color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(46, 125, 50, 0.24);
    font-weight: 500;
}
.button.is-green:focus,
.button.is-green:hover {
    background-color: #256328;
    color: #fff;
}

.is-blue {
    background-color: #1e6c8f;
    color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(30, 108, 143, 0.24);
    font-weight: 500;
}
.button.is-blue:focus,
.button.is-blue:hover {
    background-color: #195975;
    color: #fff;
}

/* TEXT COLORS */
.text-is-red {
    color: #a30000;
}
.text-is-green {
    color: #2e7d32;
}
