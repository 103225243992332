.container-ynm > * {
    width: 100%;
    margin: 0;
}

.image_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 65vh;
    max-height: 65vh;
}

.image_container img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: inherit;
    max-height: 65vh;
}
@media all and (min-height: 667px) {
    .image_container,
    .image_container img {
        max-height: 70vh;
    }
}
@media all and (min-height: 824px) {
    .image_container,
    .image_container img {
        max-height: unset;
    }
    .image_container {
        min-width: unset;
    }
}

.container-ynm .header {
    opacity: 0;
    animation: 0.75s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards init-ynm-header;
}
@keyframes init-ynm-header {
    0% {
        opacity: 0;
        transform: translateY(-1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.container-ynm .actions {
    opacity: 0;
    animation: 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s forwards init-ynm-actions;
}
@keyframes init-ynm-actions {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.container-ynm .meta-actions {
    opacity: 0;
    animation: 1s cubic-bezier(0.19, 1, 0.22, 1) 0.15s forwards
        init-ynm-meta-actions;
}
@keyframes init-ynm-meta-actions {
    0% {
        opacity: 0;
        transform: translateY(1rem);
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
