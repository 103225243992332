.pc_results_container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
}

.pc_wrapper {
    display: flex;
    border-style: solid;
    background-color: rgb(3, 3, 3);
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.pc_results_title {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.column_container {
    columns: 8 auto;
    margin-left: 40px;
    margin-top: 5px;
    font-size: small;
}

.checkbox_divider {
    margin-right: 5px;
}

.side_view {
    /* border-right: solid; */
    padding-top: 20px;
    background-color: rgb(211, 124, 42);
}

.pc_results_pagination {
    text-align: center;
    margin-bottom: 20px;
}

.pc_results_pagination button {
    width: 100px;
    height: 50px;
    margin-right: 10px;
}

.pc_results_pagination .pc_results_download_selected_samples_button {
    width: 330px;
    margin-left: 20px;
}

.pc_results_select_sample_checkbox {
    transform: scale(1.8);
    margin-left: 15px;
    vertical-align: middle;
}
